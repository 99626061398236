export enum CellContentType {
  Text = 'text',
  Number = 'number',
  Button = 'button',
  Icon = 'icon',
  List = 'dropdown',
  Date = 'date',
  Time = 'time',
  Checkbox = 'checkbox',
  Switcher = 'switcher',
  AutoComplete = 'autocomplete',
  Driver = 'driverInput'
}
