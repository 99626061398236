
























































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

import { CompanyService } from '@/shared/services/mater-data/company.service';
import { mainTypeModels, PropertyType } from './property-filter.model';
import { FilterInput } from '../../../../shared/services/filter/filter.service';
import { convertDateFieldToAPIFormat } from './../../../../utils/date.util';
import { MasterDataService } from '@/shared/services/mater-data/master-data.service';

@Component({})
export default class PropertyFilter extends Vue {
  @Prop() filter: FilterInput<string>;

  search: string = null;
  searchTypeahed: any = null;
  filterField = null;
  autoCompleateValue = false;
  driverCompleateValue = false;
  companyService = CompanyService;
  masterDataService = MasterDataService;
  convertDateFieldToAPIFormat = convertDateFieldToAPIFormat;
  propertyTypes = {};

  @Watch('$route', { immediate: true, deep: true })
  onUrlChange() {
    this.search = null;
  }

  @Watch('$route.params.type', { immediate: true })
  watchCategory(woType) {
    this.propertyTypes = mainTypeModels[woType];
    this.filterField = this.propertyTypes[0].value;
    this.filter.field = this.propertyTypes[0].value;
  }

  fieldChange() {
    this.filter.field = this.filterField;

    this.filterField === PropertyType.Driver
      ? (this.driverCompleateValue = true)
      : (this.driverCompleateValue = false);

    this.autoCompleateValue =
      [
        PropertyType.ContainerNumber,
        PropertyType.OrderNumber,
        PropertyType.ReferenceNumber,
        PropertyType.PONumber,
        PropertyType.MasterBillOfLading,
        PropertyType.Driver
      ].indexOf(this.filterField) === -1;

    this.search = null;
  }

  selectTypeadhead(val) {
    this.filter.search = val?.id;
  }

  onInputValue(val) {
    this.filter.search = val;
  }

  searchPress() {
    this.$emit('searchPress');
  }

  resetFilter() {
    this.filterField = this.propertyTypes[0].value;
    this.search = null;
  }
}
