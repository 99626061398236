import {
  ImportRegularCategoriesHeaders,
  ImportRegularTableHeaders
} from './mainTable/import-regular';
import {
  ExportRegularCategoriesHeaders,
  ExportRegularTableHeaders
} from './mainTable/export-regular';
import {
  VanRegularCategoriesHeaders,
  VanRegularTableHeaders
} from './mainTable/van-regular';
import {
  CyToCyCategoriesHeaders,
  CyToCyTableHeaders
} from './mainTable//cy-to-cy';
import {
  BareChassisCategoriesHeaders,
  BareChassisTableHeaders
} from './mainTable/bare-chassis';

export enum mainType {
  ImportShuttle = 'IMP-SHUTTLE',
  ImportRegular = 'IMP-REG',
  ExportRegular = 'EXP-REG',
  ExportShuttle = 'EXP-SHUTTLE',
  VanRegular = 'VAN',
  CyToCy = 'CY-TO-CY',
  BareChassis = 'BARE-CHASSIS'

  // TODO update after API reports wil be done
  // IMP = 'IMP',
  // EXP = 'EXP',
  // CTY = 'CTY',
  // CHS = 'CHS'
}

export const mainTableHeaders = {
  [mainType.ImportRegular]: ImportRegularTableHeaders,
  [mainType.ExportRegular]: ExportRegularTableHeaders,
  [mainType.VanRegular]: VanRegularTableHeaders,
  [mainType.CyToCy]: CyToCyTableHeaders,
  [mainType.BareChassis]: BareChassisTableHeaders
};

export const mainCategoriesHeaders = {
  [mainType.ImportRegular]: ImportRegularCategoriesHeaders,
  [mainType.ExportRegular]: ExportRegularCategoriesHeaders,
  [mainType.VanRegular]: VanRegularCategoriesHeaders,
  [mainType.CyToCy]: CyToCyCategoriesHeaders,
  [mainType.BareChassis]: BareChassisCategoriesHeaders
};
