import { FilterInput, FilterConditions } from '../filter/filter.service';
import { DivisionModule } from '@/store';

export function createDivisionFilter(): FilterInput<string> {
  return new FilterInput<string>({
    field: 'division',
    condition: FilterConditions.Equal,
    search: DivisionModule.division
  });
}
